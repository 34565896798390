import { AddressInputsProps } from '../interfaces/addressInputsProps';

export const initValues: AddressInputsProps = {
  mailing: {
    address1: '',
    address2: '',
    city: '',
    usCounty: '',
    state: '',
    country: '',
    postalCode: '',
    phone: {
      countryCode: '',
      number: '',
    },
  },
  physical: {
    address1: '',
    address2: '',
    city: '',
    usCounty: '',
    state: '',
    country: '',
    postalCode: '',
    phone: {
      countryCode: '',
      number: '',
    },
  },
};
