import { AddressResponse } from 'hooks/services/hosts/interfaces/address.response';
import { UseFormSetValue } from 'react-hook-form';
import { AddressInputsProps } from '../interfaces/addressInputsProps';

export const setValues = (
  values: AddressResponse,
  setValue: UseFormSetValue<AddressInputsProps>
) => {
  const { physical, mailing } = values;

  setValue('mailing.address1', mailing.address1);
  setValue('mailing.address2', mailing.address2);
  setValue('mailing.city', mailing.city);
  setValue('mailing.state', mailing.state);
  setValue('mailing.usCounty', mailing.usCounty);
  setValue('mailing.country', mailing.country);
  setValue('mailing.postalCode', mailing.postalCode);
  setValue('mailing.phone.countryCode', mailing.phone.countryCode);
  setValue('mailing.phone.number', mailing.phone.number);

  setValue('physical.address1', physical.address1);
  setValue('physical.address2', physical.address2);
  setValue('physical.city', physical.city);
  setValue('physical.state', physical.state);
  setValue('physical.usCounty', physical.usCounty);
  setValue('physical.country', physical.country);
  setValue('physical.postalCode', physical.postalCode);
  setValue('physical.phone.countryCode', physical.phone.countryCode);
  setValue('physical.phone.number', physical.phone.number);
};
